import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, DataStore, SortDirection } from "aws-amplify";
import { AmpUser } from "./models";
import onAmpUserCreate from "./data/create/ampUser";
import styled from "@emotion/styled";
import {BrowserRouter, Route, Routes} from "react-router-dom";
// import TrackingTable from "./components/TrackingTable";
// import Uploader from "./components/Uploader";
import TableRecordsProvider from "./data/providers/TableRecordsProvider";
import {RequireAuth} from "./components/auth/RequireAuth";
import MaterialTable from "./components/MaterialTable";
import SheetUploader from "./components/SheetUploader";
import {Login} from "./components/auth/Login";

const Title = styled("h1")`
  text-align: center;
  text-transform: uppercase;
  color: #a7d7c5;
  margin-bottom: 8px;
`;

const theme = {
    formContainer: {
        margin: 0,
        padding: "8px 24px 24px"
    },
    formSection: {
        backgroundColor: "#ffffff",
        borderRadius: "4px"
    },
    sectionHeader: {
        color: "#74b49b"
    },
    sectionFooterSecondaryContent: {
        color: "#303952"
    },
    inputLabel: {
        color: "#74b49b"
    },
    input: {
        backgroundColor: "#f4f9f4",
        color: "#74b49b"
    },
    hint: {
        color: "#74b49b"
    },
    button: {
        borderRadius: "3px",
        backgroundColor: "#a7d7c5"
    },
    a: {
        color: "#a7d7c5"
    }
};

function App() {
  const [ampUser, setAmpUser] = useState(null);
  const [showLogs, setShowLogs] = useState(false);
  const [shipmentRecords, setShipmentRecords] = useState([]);
  const [tableRecords, setTableRecords] = useState([]);
    const [dataLoading, setDataLoading] = useState(true)
    const [displayNoti, setDisplayNoti] = useState(false)
    const [notiMessage, setNotiMessage] = useState('')
    const [notiSeverity, setNotiSeverity] = useState('')
    const [networkConnected, setNetworkConnected] = useState( true)


  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

// todo - should probably group these into a separate component
    const displayNetworkReconnectedNoti = () => {
        setNotiMessage('Connected to network')
        setNotiSeverity('info')
        setDisplayNoti(true);
    }

    const displayRecordAddedNoti = () => {
        setNotiMessage('Record Added!')
        setNotiSeverity('success')
        setDisplayNoti(true);
    }

    const displayUploadSuccessNoti = () => {
        setNotiMessage('Uploaded File Successfully!')
        setNotiSeverity('success')
        setDisplayNoti(true);
    }

    const displayErrorNoti = (errMsg) => {
        setNotiMessage(errMsg)
        setNotiSeverity('warning')
        setDisplayNoti(true);
    }

    window.addEventListener("offline", (event) => {
        setNetworkConnected(false)
        displayErrorNoti('lost network connection')
    })

    window.addEventListener("online", (event) => {
        setNetworkConnected(true)
        displayNetworkReconnectedNoti()
    })

    // useEffect(() => {
    //     DataStore.clear();
    // }, []);

    // useEffect( console.log('auth user', user);
    // useEffect(() => {
    //     if (showLogs) {
    //         console.log('auth user', user);
    //     }
    // }, [user, showLogs]);

    // useEffect( console.log('auth user', user);
    useEffect(() => {
        if (showLogs) {
            console.log('authStatus', authStatus);
        }
    }, [authStatus]);

    // DataStore.observeQuery(AmpUser
    useEffect (() => {
        // if (user.attributes.verified) {
        if (authStatus === 'authenticated' && user) {
            const subscription = DataStore.observeQuery(
                AmpUser,
                f => f.email.eq(user.attributes.email),
                // f => f,
                {
                    sort: s => s.name(SortDirection.ASCENDING)
                }
            ).subscribe(snapshot => {
                const { items, isSynced } = snapshot;
                if (showLogs) {
                    console.log(`AMPUSER [Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
                    console.log('AMPUSER [Snapshot] items', items);
                }
                if (isSynced) {
                    if (items.length > 0) {
                        if (ampUser !== items[0]) {
                            setAmpUser(items[0]);
                        }
                    } else {
                        // TODO - NEED BETTER TOGGLE LOGIC HERE, CREATES DUPE RECORDS,
                        //  IF A COGNITO USER EXISTS, ALWAYS CREATES AMP USER,
                        //  CORRECT AMP USER HAS EMAIL REC
                        if (authStatus === 'authenticated') {
                            onAmpUserCreate(user);
                        }
                    }
                }
            });
        }
    }, [user, authStatus]);



    return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                    <Route path="/" element={
                        <TableRecordsProvider>
                            <RequireAuth>
                        <MaterialTable />
                            </RequireAuth>
                        </TableRecordsProvider>
                    } />
                    <Route path="upload" element={
                        <RequireAuth>
                        <SheetUploader />
                        </RequireAuth>
                    } />
                    <Route path="/login" element={
                        <Login />
                    } />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default () => (
    <Authenticator.Provider>
      {/*<AmpUserDataProvider>*/}
              <App />
      {/*</AmpUserDataProvider>*/}
    </Authenticator.Provider>
);