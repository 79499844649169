import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { ShipmentRecord } from '../../models';
import TableRecordsContext from "../contexts/TableRecordsContext";
import {SortDirection} from "aws-amplify";

const dayOfWeekMap = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
}

// todo - move to a utils type dir with the corresponding map/obj
const dayOfWeekFromDate = (localeDateObj) => {
    let dayOfWeek;
    try {
        const cellDate = new Date(localeDateObj).getDay();
        dayOfWeek = dayOfWeekMap[cellDate];
    } catch (e) {
        console.error(e)
        dayOfWeek = '-';
    }
    return dayOfWeek;
}

const TableRecordsProvider = ({ children }) => {
    const [shipmentRecordRecs, setShipmentRecordRecs] = useState([]);
    const [tableRecords, setTableRecords] = useState([]);

    useEffect(() => {
        const subscription = DataStore.observeQuery(
            ShipmentRecord,
            sr => sr,
            {
                sort: s => s.createdAt(SortDirection.ASCENDING)
            })
            .subscribe(snapshot => {
                const { items, isSynced } = snapshot;
                if (isSynced) {
                    setShipmentRecordRecs(items);
                }
                // Update the posts data
            });
        // Remember to unsubscribe when the component unmounts
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (shipmentRecordRecs) {
            const sortedRecords = shipmentRecordRecs.sort((a, b) => {
                if (a.updatedAt > b.updatedAt) {
                    return -1;
                } else {
                    return 1;
                }
            })
            setTableRecords(
                sortedRecords.map((eachItem) => {
                    return ({
                        id: eachItem.id,
                        _version: eachItem._version,
                        shipmentID: eachItem.shipmentID,
                        clientName: eachItem.clientName,
                        origin: eachItem.origin,
                        destination: eachItem.destination,
                        mode: eachItem.mode,
                        etdDayOfWeek: dayOfWeekFromDate(eachItem.estimatedDeparture),
                        estimatedDeparture: eachItem.estimatedDeparture,
                        etaDayOfWeek: dayOfWeekFromDate(eachItem.estimatedArrival),
                        estimatedArrival: eachItem.estimatedArrival,
                        status: eachItem.status
                    })
                })
            )
        }
    }, [shipmentRecordRecs]);

    return (
        <TableRecordsContext.Provider value={{tableRecords}}>
            {children}
        </TableRecordsContext.Provider>
    );
};

export default TableRecordsProvider;
