// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserStatus = {
  "FREE": "FREE",
  "SUBSCRIBER": "SUBSCRIBER",
  "PENDING": "PENDING",
  "CANCELLED": "CANCELLED",
  "DELETED": "DELETED",
  "ARCHIVED": "ARCHIVED"
};

const { AmpUser, ShipmentRecord } = initSchema(schema);

export {
  AmpUser,
  ShipmentRecord,
  UserStatus
};