import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
// import {Button} from "@rebass/emotion";
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import Input from '@mui/material/Input';
import { createShipmentRecord } from '../data/create/shipmentRecord';
// import {Input} from "@rebass/forms";
// import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";
// import {createShipmentRecord} from "../graphql/mutations";
// import createShipmentRecord from "../data/create/shipmentRecord";
// import {API, graphqlOperation} from "aws-amplify";

// const UploadButton = styled(Button)`
//   background-color: #74b49b;
//   cursor: pointer;
//   margin: 12px 12px 18px;
//   //align-self: flex-end;
// `;
//
// const StyledInput = styled(Input)`
//   color: #74b49b;
//   border-radius: 3px;
//   background-color: #f4f9f4;
//   margin-left: 12px;
//   margin-right: 12px;
//   margin-top: 12px;
//   //max-width: 20%;
//   min-width: 40px;
//   //align-self: flex-end;
// `;

export default function SheetUploader(props){
    const { networkConn, lostConnection, regainedConnection } = props;
    const [sheetData, setSheetData] = useState([])
    const [currentSheet, setCurrentSheet] = useState({})
    const [networkConnected, setNetworkConnected] = useState( true)

    useEffect(() => {
        console.log('sheetData', sheetData);
    }, [sheetData]);

    // useEffect(() => {
    //     setNetworkConnected(networkConn)
    // }, [networkConn])
    //
    // window.addEventListener("offline", (event) => {
    //     setNetworkConnected(false)
    //     lostConnection();
    // })
    //
    // window.addEventListener("online", (event) => {
    //     setNetworkConnected(true)
    //     regainedConnection();
    // })

    const UploadsMenu = styled("div")`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 420px;
      min-width: 120px;
    `;

    const PreviewDiv = styled("div")`
    
    `

    // TODO - start here, make file upload work
    // const handleSheetUpload = (event) => {
    //     const uploadedFile = event.target.files[0];
    //     readFile(uploadedFile)
    //         .then((uploadedData) => setSheetData(uploadedData))
    //         .catch((err) => console.error(err))
    // }

    // source: https://stackoverflow.com/questions/16229494/converting-excel-date-serial-number-to-date-using-javascript
    function ExcelDateToJSDate(date) {
        return new Date(Math.round((date - 25569)*86400*1000)).toLocaleDateString();
    }

    const writeSheetRecordsToDB = async (sheetRecordsArr) => {
        try {
            // Transform sheet records into desired structure
            const filteredRowsArr = sheetRecordsArr.map((eachRecord) => {
                const newRecord = {
                    shipmentID: eachRecord.ShipmentID,
                    clientName: eachRecord.ClientName,
                    origin: eachRecord.Origin,
                    destination: eachRecord.Destination,
                    mode: eachRecord.Mode,
                    estimatedDeparture: eachRecord.EstimatedDeparture,
                    estimatedArrival: eachRecord.EstimatedArrival,
                    status: eachRecord.Status
                }
                if (newRecord.shipmentId) {
                    return newRecord;
                } else {
                    return null;
                }
            }).filter(Boolean);  // Remove nulls

            // Prepare promises to save each record to the database
            const savePromises = filteredRowsArr.map(eachRecord => {
                const jsDepartureDate = ExcelDateToJSDate(eachRecord.estimatedDeparture);
                const jsArrivalDate = ExcelDateToJSDate(eachRecord.estimatedArrival);
                eachRecord['estimatedDeparture'] = jsDepartureDate;
                eachRecord['estimatedArrival'] = jsArrivalDate;

                if (networkConnected) {
                    // Return the promise from saving the record using DataStore
                    return createShipmentRecord(eachRecord)
                        // .then(newRecId => {
                        //     eachRecord['newRecId'] = newRecId;
                        //     return eachRecord;
                        // });
                } else {
                    // If not connected, you can resolve immediately or handle offline sync
                    return Promise.resolve(eachRecord);
                }
            });
            // Wait for all save operations to complete
            const savedRecords = await Promise.all(savePromises);

            console.log('savedRecords', savedRecords);

        } catch (error) {
            console.error("Error saving records:", error);
        }
    }

            // const writeSheetRecordsToDB = async (sheetRecordsArr) => {
    //     // go through each sheet record and write it to the db as a new record
    //     // then add it to state
    //     const filteredRowsArr = []
    //     await sheetRecordsArr.forEach((eachRecord) => {
    //         try{
    //             const newRecord = {
    //                 shipmentId: eachRecord.ShipmentID,
    //                 clientName: eachRecord.ClientName,
    //                 origin: eachRecord.Origin,
    //                 destination: eachRecord.Destination,
    //                 mode: eachRecord.Mode,
    //                 estimatedDeparture: eachRecord.EstimatedDeparture,
    //                 estimatedArrival: eachRecord.EstimatedArrival,
    //                 status: eachRecord.Status
    //             }
    //             if (newRecord.shipmentId) {
    //                 filteredRowsArr.push(newRecord)
    //             }
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     })
    //     await filteredRowsArr.forEach((eachRecord) => {
    //         try {
    //             if (networkConnected) {
    //                 const jsDepartureDate = ExcelDateToJSDate(eachRecord.estimatedDeparture)
    //                 const jsArrivalDate = ExcelDateToJSDate(eachRecord.estimatedArrival)
    //                 eachRecord['estimatedDeparture'] = jsDepartureDate;
    //                 eachRecord['estimatedArrival'] = jsArrivalDate;
    //                 const newRecId = createShipmentRecord(eachRecord);
    //                 eachRecord['newRecId'] = newRecId;
    //             } else {
    //                 // todo - use local data store to sync to cloud when we get connection back? what about size of data?
    //             }
    //
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     })
    //     // console.log({filteredRowsArr})
    // }

    // const saveSheet = () => {
    //     if (networkConnected) {
    //         const result = generateObjects(currentSheet)
    //         writeSheetRecordsToDB(result)
    //             .then(() => props.onUploadSuccess())
    //             .catch((err) => console.error(err))
    //     } else {
    //         //
    //     }
    // }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            parseExcel(file);
        }
    };

    const parseExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            let data = XLSX.utils.sheet_to_json(worksheet);

            // Remove spaces from column names
            data = data.map(row => {
                const newRow = {};
                Object.keys(row).forEach(key => {
                    newRow[key.replace(/\s+/g, '')] = row[key];
                });
                return newRow;
            });
            setSheetData(data);
        };
        reader.readAsBinaryString(file);
    };

    useEffect(() => {
        if (sheetData?.length > 0) {
            writeSheetRecordsToDB(sheetData);
        }
    }, [sheetData]);

    return (
        <div>
            <Input type="file" accept=".xlsx" onChange={handleFileUpload} />
            <pre>{JSON.stringify(sheetData, null, 2)}</pre>
        </div>
    )
}