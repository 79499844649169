import { ShipmentRecord } from "../../models";
import { DataStore } from "@aws-amplify/datastore";
export async function createShipmentRecord(rowData) {
    const newRec = await DataStore.save(
        new ShipmentRecord({
            shipmentId: rowData.shipmentId,
            clientName: rowData.clientName,
            origin: rowData.origin,
            destination: rowData.destination,
            mode: rowData.mode,
            estimatedDeparture: rowData.estimatedDeparture,
            estimatedArrival: rowData.estimatedArrival,
            status: rowData.status
        })
    );
    return newRec.id;
}

// module.exports = createShipmentRecord;